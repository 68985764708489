import { CommonState } from '@frientrip/domain';
import { Dictionary } from 'vue-router/types/router';

export enum MagazineOrderType {
  CREATED_AT_DESC = 'CREATED_AT_DESC', // 작성일순
  PUBLISH_START_DATE_DESC = 'PUBLISH_START_DATE_DESC', // 발행일순
}

export interface MagazineFilter {
  publishStartDateTerm?: {
    startPublishStartDate: Date;
    endPublishStartDate: Date;
  };
  statusIn?: CommonState[];
  titleLike?: string;
  publishStartDateNotNull?: boolean;
}

export interface MagazineFilterQuery
  extends Dictionary<string | string[] | undefined> {
  startPublishStartDate?: string;
  endPublishStartDate?: string;
  statusIn?: CommonState[];
  titleLike?: string;
}
