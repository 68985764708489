











































































































import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
} from 'element-ui';
import Vue from 'vue';
import { apolloClient } from '@/apolloClient';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { CommonState, Connection } from '@frientrip/domain';
import { getDomainName, getLegacyAdminUrl, isHostAdmin } from '@/env';
import { MagazineApiResponse } from '../models/Magazine';
import Instant from '@/components/Labels/Instant.vue';
import Spinner from '@/components/Spinner.vue';
import { MAGAZINES_QUERY } from '@/domain/magazine/queries/query';
import { MagazineOrderType } from '@/domain/magazine/models/param/magazineSearchFilerParam';
import { MagazineConvertService } from '@/domain/magazine/service/MagazineConvertService';
import { MagazineSaveService } from '@/domain/magazine/service/MagazineSaveService';

interface MagazineFilter {
  publishStartDateTerm?: {
    startPublishStartDate: number;
    endPublishStartDate: number;
  };
  startPublishStartDate?: number;
  endPublishStartDate?: number;
  statusIn?: CommonState[];
  titleLike?: string;
  publishStartDateNotNull?: boolean;
}

interface MagazineSearchParam {
  page?: number;
  size?: number;
  filter?: MagazineFilter;
  orderType: MagazineOrderType;
}

const magazineSaveService = new MagazineSaveService(apolloClient);
const magazineConvertService = new MagazineConvertService();

export default Vue.extend({
  name: 'MagazineSearchList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    Instant,
    Spinner,
  },
  data() {
    return {
      isHostAdmin: isHostAdmin(),
      loading: 0,
      magazines: [] as MagazineApiResponse[],
      totalCount: 0,
      size: 10,
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
    filter(): MagazineFilter {
      const query = this.$route.query as MagazineFilter;

      return {
        publishStartDateTerm:
          query.startPublishStartDate && query.endPublishStartDate
            ? {
                startPublishStartDate: query.startPublishStartDate,
                endPublishStartDate: query.endPublishStartDate,
              }
            : undefined,
        statusIn: query.statusIn || [CommonState.ACTIVE, CommonState.INACTIVE],
        titleLike: query.titleLike,
      };
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
    fripUrl(): string {
      return getDomainName();
    },
  },
  methods: {
    convertMagazineStatus(status: CommonState) {
      switch (status) {
        case CommonState.ACTIVE:
          return 'OPEN';
        case CommonState.INACTIVE:
          return 'SEMI-OPEN';
        default:
          return 'UNKNOWN';
      }
    },
    linkGen(pageNum: number) {
      return {
        query: {
          ...this.$route.query,
          page: pageNum > 1 ? pageNum : undefined,
        },
      };
    },
    refresh() {
      this.$apollo.queries.magazines.refresh();
    },
    openMagazineDetail(row: MagazineApiResponse) {
      const magazineId = row.id;
      const magazineStatus = row.status;
      const domainUrl = getDomainName();
      if (magazineStatus === CommonState.ACTIVE) {
        window.open(domainUrl + `/magazine/${magazineId}`);
      }
    },
    openLegacyMagazineAdmin: function () {
      const path = getLegacyAdminUrl() + '/product/ticket/magazine-list';
      window.open(path);
    },
    async deleteMagazine(magazine: MagazineApiResponse): Promise<void> {
      this.$modal.show(
        {
          title: '매거진을 삭제하시겠습니까?',
          message: '제목: ' + magazine.title,
          type: 'primary',
          showCancelButton: true,
          confirmText: '네',
          cancelText: '아니오',
        },
        async () => {
          try {
            magazine.status = CommonState.DELETED;
            const magazineForm =
              magazineConvertService.convertApiResponseToForm(magazine);

            await magazineSaveService.saveMagazine(
              magazineConvertService.convertFormToSaveParam(magazineForm)
            );
            this.refresh();

            this.$modal.show({
              title: '매거진 삭제 완료',
              message: '매거진 삭제를 완료했습니다!',
              type: 'success',
            });
          } catch (e) {
            this.$notify({
              title: '매거진 삭제 실패',
              message: e.message,
              type: 'warning',
            });
          }
        }
      );
    },
  },
  apollo: {
    magazines: {
      query: MAGAZINES_QUERY,
      variables(): MagazineSearchParam {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: this.filter,
          orderType: MagazineOrderType.CREATED_AT_DESC,
        };
      },
      deep: true,
      error(e: ApolloError) {
        console.error(e);
        this.$notify({
          title: '매거진 불러오기 실패',
          message: e.message,
          type: 'warning',
        });
      },
      result(
        result: ApolloQueryResult<{
          fripMagazine: { magazines: Connection<MagazineApiResponse> };
        }>
      ) {
        this.totalCount = result.data.fripMagazine.magazines.totalCount;
        this.magazines = result.data.fripMagazine.magazines.edges.map(
          edge => edge.node
        );
      },
      update: (data: {
        fripMagazine: { magazines: Connection<MagazineApiResponse> };
      }) => data.fripMagazine.magazines.edges.map(edge => edge.node),
    },
  },
});
