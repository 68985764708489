var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-card',{attrs:{"title":("검색 결과: " + _vm.totalCount + "건"),"body-padding":""},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.totalCount > 0)?_c('b-pagination-nav',{staticClass:"mt-3",attrs:{"align":"center","link-gen":_vm.linkGen,"number-of-pages":_vm.numberOfPages,"use-router":""}}):_vm._e(),_c('b-card-footer',{staticClass:"text-right"},[_c('b-row',{staticClass:"float-left mx-3"},[_c('frip-button',{attrs:{"type":"secondary","loading":_vm.loading > 0},on:{"click":_vm.openLegacyMagazineAdmin}},[_vm._v("구 매거진 관리자 페이지로 가기 ")])],1),_c('b-row',{staticClass:"float-right mx-3"},[_c('router-link',{attrs:{"to":"./create"}},[_c('frip-button',{attrs:{"type":"primary","loading":_vm.loading > 0}},[_vm._v("매거진 추가 ")])],1)],1)],1)]},proxy:true}])},[(_vm.loading > 0)?_c('spinner',{staticStyle:{"min-height":"200px"}}):_vm._e(),_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center center-head",attrs:{"empty-text":"검색 결과가 없습니다.","header-row-class-name":"thead-light","header-cell-class-name":"center-table","data":_vm.magazines}},[_c('el-table-column',{attrs:{"label":"매거진ID","prop":"id","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-link',{attrs:{"to":("./list/" + (row.id))}},[_vm._v(_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"label":"제목","prop":"title","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-link',{attrs:{"to":("./list/" + (row.id))}},[_vm._v(_vm._s(row.title))])]}}])}),_c('el-table-column',{attrs:{"label":"캐치프라이즈","prop":"catchphrase","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.catchphrase))]}}])},[_vm._v(" .03 ")]),_c('el-table-column',{attrs:{"label":"상태","prop":"status","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.convertMagazineStatus(row.status)))]}}])}),_c('el-table-column',{attrs:{"label":"매거진 발행일","prop":"publishStartDate","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.publishStartDate)?_c('instant',{attrs:{"at":row.publishStartDate,"short":"true"}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"담당자","prop":"workerId","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.worker.nickname))]}}])}),_c('el-table-column',{attrs:{"label":"삭제","prop":"remove","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-button',{staticClass:"my-1",attrs:{"outline":"","size":"sm"},on:{"click":function($event){return _vm.deleteMagazine(row)}}},[_vm._v("삭제 ")])]}}])}),_c('el-table-column',{attrs:{"label":"바로가기","prop":"removeAndPaste","class-name":"text-center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-button',{staticClass:"my-1",attrs:{"outlineb":"","size":"sm"},on:{"click":function($event){return _vm.openMagazineDetail(row)}}},[_vm._v("바로가기 ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }