






























































import Vue from 'vue';
import { Option, Select } from 'element-ui';
import FripButton from '@/components/FripComponents/FripButton.vue';
import Icon from '@/components/Icon/Icon.vue';
import { OptionType } from '@/common/model/Option';
import {
  MagazineFilter,
  MagazineFilterQuery,
} from '@/domain/magazine/models/param/magazineSearchFilerParam';
import { Term } from '@/common/Term';
import VueRouter from 'vue-router';
import { CommonState } from '@frientrip/domain';

export default Vue.extend({
  name: 'MagazineSearchFilterForm',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    FripButton,
    Icon,
  },
  data() {
    const query = this.$route.query as MagazineFilter;
    let searchTarget = 'titleLike';
    let searchKeyword = '';
    if (query.titleLike) {
      searchTarget = 'titleLike';
      searchKeyword = query.titleLike;
    }
    return {
      searchKeywordPlaceholder: '',
      searchTarget: searchTarget,
      searchKeyword: searchKeyword,
      searchStatusIn: [],
      searchTerm: {
        startedAt: query.publishStartDateTerm?.startPublishStartDate,
        endedAt: query.publishStartDateTerm?.endPublishStartDate,
      } as Term,
      totalCount: 0,
    };
  },
  computed: {
    magazineOptions(): OptionType[] {
      return [{ value: 'titleLike', text: '매거진 제목' }];
    },
    magazineStatus(): OptionType[] {
      return [
        { value: CommonState.ACTIVE, text: 'OPEN' },
        { value: CommonState.INACTIVE, text: 'SEMI-OPEN' },
      ];
    },
  },
  methods: {
    clear() {
      this.searchTarget = 'titleLike';
      this.searchKeyword = '';
      this.searchTerm = { startedAt: null, endedAt: null };

      this.$router.push({ query: undefined });
    },
    linkGen(pageNum: number) {
      return {
        query: {
          ...this.$route.query,
          page: pageNum > 1 ? pageNum : undefined,
        },
      };
    },
    buildFilter() {
      const filter: MagazineFilterQuery = {};

      switch (this.searchTarget) {
        case 'titleLike':
          filter.titleLike = this.searchKeyword;
      }

      if (this.searchTerm.startedAt) {
        filter.startPublishStartDate = this.$moment(
          this.searchTerm.startedAt
        ).format('YYYY-MM-DD');
        filter.endPublishStartDate = this.$moment(
          this.searchTerm.endedAt
        ).format('YYYY-MM-DD');
      }

      filter.statusIn = [CommonState.INACTIVE, CommonState.ACTIVE];
      if (this.searchStatusIn.length > 0) {
        filter.statusIn = this.searchStatusIn;
      }

      this.$router.push({ query: filter }).catch(err => {
        if (
          VueRouter.isNavigationFailure(
            err,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          this.$emit('refresh', true);
        }
      });
    },
  },
});
