








import Vue from 'vue';
import { isHostAdmin } from '@/env';
import MagazineSearchList from '@/domain/magazine/components/MagazineSearchList.vue';
import MagazineSearchFilterForm from '@/domain/magazine/components/MagazineSearchFilterForm.vue';

export default Vue.extend({
  components: {
    MagazineSearchFilterForm,
    MagazineSearchList,
  },
  data() {
    return {
      isHostAdmin: isHostAdmin(),
    };
  },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
